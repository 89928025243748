import React,{Component}  from "react"
import { Link } from "gatsby"


import Formlayout from "../components/formlayout.js"
import Image from "../components/image"
import SEO from "../components/seo"
import "../styles/formlayout.sass"

const Thanku = () => (
  <>
    <SEO title="Portal" />  
      <Formlayout>
        <div className="intro"  style={{
          height: '100vh'
        }}>
            <div>
            <h1>Thank You for taking our Questionnaire</h1>
            <h1>Your answers have been submitted </h1>
          </div>
          <Link to="/">
            <input style={{
              margin: '5% 40% 0% 40%',
              justifyContent: 'space-evenly'
              
            }}  type="submit" value="Home">
            </input>
          </Link> 
          <Link to="/blog/1">
            <input style={{
              margin: '5% 40% 0% 40%',
              justifyContent: 'space-evenly'
              
            }}  type="submit" value="Blog">
            </input>
          </Link> 
        </div>
      </Formlayout>
  </>
)

export default Thanku